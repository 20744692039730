<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="user"></a-icon>
          <span>用户设置</span>
        </a-space>
      </template>
      <span>用户设置包含用户、角色等设置。</span>
    </a-page-header>

    <a-card :bordered="false" style="margin-bottom: 16px">
      <list-layout
        :getTableDataFunc="getTableDataFunc"
        :columnDefaultSelected="columnDefaultSelected"
        :columnsAll="columnsAll"
        :toolsList="['setting', 'reload', 'search', 'leftTool']"
        :refresh="refresh"
        :searchTypeOptions="[{ label: '用户名', value: 'username' }]"
         :defaultSelected="tableSelectData"
        @tableSelectData="(v) => tableSelectData = v"
      >
        <!-- 工具栏 -->
        <template #leftTool>
          <template>
            <create-button
              v-permission="{action: 'base.user.create', effect: 'disabled'}"
              @click="$refs.inputDrawer.show()"
            ></create-button>
          </template>
        </template>
        <!-- 表格自定义列 -->
        <template #username="{ slotProps }">
          <a-tooltip placement="topLeft" :title="slotProps.text">
            {{ slotProps.text }}
          </a-tooltip>
        </template>
        <template #organization="{ slotProps }">
          <a-tooltip placement="topLeft" v-if="slotProps.text" :title="slotProps.text.name">
            {{ slotProps.text.name }}
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <template #role="{ slotProps }">
          {{ slotProps.text ? slotProps.text.name : '-' }}
        </template>
        <template #authMethod="{ slotProps }">
          {{ $t(`auth_method.${slotProps.text}`) }}
        </template>
        <template #active="{ slotProps }">
          <boolean-tag :value="slotProps.text"></boolean-tag>
        </template>
        <template #operation="{ slotProps }">
          <edit-button
           v-permission="{action: 'base.user.update', effect: 'disabled'}"
            @click="$refs.inputDrawer.show(slotProps.record.id)"
          ></edit-button>
          <delete-button
           v-permission="{action: 'base.user.delete', effect: 'disabled'}"
            v-if="slotProps.record.username !== 'admin'"
            @confirm="confirm(slotProps.record.id)"
          ></delete-button>
        </template>
      </list-layout>
    </a-card>

    <input-drawer ref="inputDrawer" @updated="confirm"></input-drawer>
  </div>
</template>

<script>
import { deleteUser, getUserList } from '@/api/user'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import CreateButton from '@/components/button/CreateButton'
import EditButton from '@/components/button/EditButton'
import BooleanTag from '@/components/tag/BooleanTag'
import InputDrawer from './modules/InputDrawer.vue'

export default {
  name: 'User',
  components: {
    BooleanTag,
    DeleteButton,
    EditButton,
    InputDrawer,
    ListLayout,
    CreateButton
  },
  data () {
    return {
      getTableDataFunc: getUserList,
      columnsAll: [
        {
          dataIndex: 'username',
          title: '用户名',
          fixed: 'left',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'username'
          }
        },
        {
          dataIndex: 'nickname',
          title: '姓名',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'nickname'
          }
        },
        {
          dataIndex: 'phone_number',
          title: '手机号码',
          width: 160,
          scopedSlots: {
            customRender: 'phoneNumber'
          }
        },
        {
          dataIndex: 'email_address',
          title: '邮箱地址',
          ellipsis: true,
          width: 200,
          scopedSlots: {
            customRender: 'emailAddress'
          }
        },
        {
          dataIndex: 'organization',
          title: '所属组织',
          width: 200,
          scopedSlots: {
            customRender: 'organization'
          }
        },
        {
          dataIndex: 'role',
          title: '角色',
          width: 120,
          scopedSlots: {
            customRender: 'role'
          }
        },
        {
          dataIndex: 'auth_method',
          title: '认证方式',
          width: 120,
          scopedSlots: {
            customRender: 'authMethod'
          }
        },
        {
          dataIndex: 'active',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'active'
          },
          filters: [
            {
              text: '正常',
              value: 'true'
            },
            {
              text: '停用',
              value: 'false'
            }
          ],
          filterMultiple: false
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'username',
        'nickname',
        'phone_number',
        'email_address',
        'role',
        'organization',
        'auth_method',
        'active'
      ],
      refresh: false,
      tableSelectData: []
    }
  },
  methods: {
    async confirm (id = '') {
      if (id) {
        await deleteUser(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
      }
      this.refresh = !this.refresh
    }
  }
}
</script>

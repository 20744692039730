<template>
  <a-select
    :allow-clear="false"
    :filter-option="false"
    :label-in-value="true"
    :options="options"
    :show-search="true"
    :value="selected"
    @change="change"
    @popupScroll="popupScroll"
    @search="search"
  ></a-select>
</template>

<script>
import { getRoleList } from '@/api/role'

export default {
  name: 'RoleSelect',
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      form: {
        page: 1,
        pageSize: 20
      },
      selected: undefined,
      options: [],
      total: 0,
      timer: null
    }
  },
  mounted () {
    if (this.value) {
      this.selected = {
        key: this.value.id,
        label: this.value.name
      }
    }
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.name) params.name = this.form.name
      getRoleList(params).then(res => {
        const data = res.data
        this.total = data.total
        data.data.forEach(role => {
          if (
            this.options.filter(option => option.value === role.id)
              .length === 0
          ) {
            this.options.push({
              key: role.id,
              label: role.name
            })
          }
        })
      })
    },
    change (value) {
      this.$emit('input', {
        id: value.key,
        name: value.label
      })
    },
    popupScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        if (this.total > this.form.page * this.form.pageSize) {
          this.form.page += 1
          this.fetch()
        }
      }
    },
    search (value) {
      this.form.name = value
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.options = []
        this.fetch()
      }, 500)
    }
  },
  watch: {
    value: {
      handler (v) {
        if (v) {
          this.selected = {
            label: v.name,
            key: v.id
          }
        }
      },
      deep: true
    }
  }
}
</script>

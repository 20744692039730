<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? detail.username : '用户创建'"
    :visible="visible"
    :width="1000"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :wrapperCol="{ span: 20 }"
    >
      <a-form-model-item label="用户名" prop="username">
        <a-input
          v-model="form.username"
          :disabled="isUpdate"
          :max-length="128"
        ></a-input>
      </a-form-model-item>
      <template v-if="!isUpdate">
        <a-form-model-item label="密码策略">
          <a-radio-group v-model="strategy" button-style="solid">
            <a-radio-button value="auto">邮件发送</a-radio-button>
            <a-radio-button value="manual">手动输入</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          v-if="strategy !== 'auto'"
          label="密码"
          prop="password"
          :rules="[{
            message: '请输入密码',
            required: true,
            trigger: 'blur'
          }]"
        >
          <a-input-password v-model="form.password" :max-length="128"></a-input-password>
        </a-form-model-item>
      </template>
      <a-form-model-item label="姓名" prop="nickname">
        <a-input v-model="form.nickname" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="电话号码" prop="phoneNumber">
        <a-input v-model="form.phoneNumber"></a-input>
      </a-form-model-item>
      <a-form-model-item label="邮箱地址" prop="emailAddress">
        <a-input v-model="form.emailAddress" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="角色" prop="role">
        <role-select v-model="form.role"></role-select>
      </a-form-model-item>
      <a-form-model-item label="所属组织" prop="organizationId">
        <organization-tree-select v-model="form.organizationId"></organization-tree-select>
      </a-form-model-item>
      <a-form-model-item label="认证方式" prop="authMethod">
        <a-select v-model="form.authMethod" :options="options"></a-select>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="active">
        <a-switch v-model="form.active"></a-switch>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { createUser, getUser, updateUser } from '@/api/user'
import RoleSelect from '@/components/select/RoleSelect.vue'
import { encrypt } from '@/utils'
import OrganizationTreeSelect from '@/components/select/OrganizationTreeSelect.vue'

export default {
  name: 'InputDrawer',
  components: {
    RoleSelect,
    OrganizationTreeSelect
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      strategy: 'auto',
      detail: {
        id: '',
        username: '',
        nickname: '',
        phoneNumber: '',
        emailAddress: '',
        role: undefined,
        organization: undefined,
        authMethod: '',
        active: false
      },
      form: {
        username: '',
        password: '',
        nickname: '',
        phoneNumber: '',
        emailAddress: '',
        role: undefined,
        organizationId: '',
        authMethod: '',
        active: true
      },
      options: [
        { label: '本地', value: 'local' },
        { label: 'SSO', value: 'sso' }
      ],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['publicKey']),
    rules () {
      const obj = {
        username: [{
          message: '请输入用户名',
          required: true,
          trigger: 'blur'
        }],
        nickname: [{
          message: '请输入姓名',
          required: true,
          trigger: 'blur'
        }],
        role: [{
          message: '请选择角色',
          required: true,
          trigger: 'change'
        }],
        organizationId: [{
          message: '请选择所属组织',
          required: true,
          trigger: 'change'
        }],
        authMethod: [{
          message: '请选择认证方式',
          required: true,
          trigger: 'change'
        }]
      }
      if (!this.isUpdate && this.strategy === 'auto') {
        obj.emailAddress = [
          {
            message: '请输入邮箱地址',
            required: true,
            trigger: 'blur'
          },
          {
            message: '请输入正确的邮箱地址',
            type: 'email',
            trigger: 'blur'
          }
        ]
      }
      return obj
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getUser(id)
          .then(res => {
            this.detail = res.data
            this.form.username = this.detail.username
            this.form.nickname = this.detail.nickname
            this.form.phoneNumber = this.detail.phone_number
            this.form.emailAddress = this.detail.email_address
            this.form.role = this.detail.role
            this.form.organizationId = this.detail.organization.id || ''
            this.form.authMethod = this.detail.auth_method
            this.form.active = this.detail.active
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        username: '',
        password: '',
        nickname: '',
        phoneNumber: '',
        emailAddress: '',
        role: undefined,
        organizationId: '',
        authMethod: '',
        active: true
      }
      this.detail.id = ''
      this.$refs.form.resetFields()
      this.visible = false
      this.loading = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = {
            nickname: this.form.nickname,
            role_id: this.form.role.id,
            active: this.form.active,
            organization_id: this.form.organizationId,
            auth_method: this.form.authMethod
          }
          if (this.form.phoneNumber) params.phone_number = this.form.phoneNumber
          if (this.form.emailAddress) params.email_address = this.form.emailAddress
          this.loading = true
          if (this.detail.id) {
            updateUser(this.detail.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('updated')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            params.username = this.form.username
            if (this.strategy !== 'auto') params.password = encrypt(this.publicKey, this.form.password)
            createUser(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('updated')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
